
// index page js

//  if ($(window).width() <= 991) {
//  $(".page-content > .container").removeClass();
// }
$(document).on("click", ".checkBoxID", function () {
  $("#buttonID").attr("disabled", !this.checked);
});
 
$(document).on("click", "#selectAll", function (e) {
    var table = $(e.target).closest("table");
  $("td input:checkbox", table).prop("checked", this.checked);
});

// $("#btn_view").click(function() {
// 	console.log("btn_view");
//     var fired_button = $(this).val();
// 	obj = JSON.stringify(fired_button);
// 	console.log(obj);
// 	$("#order_details").css("display", "block");
// 	$("#order_details_top").css("display", "block");
// 	$("#order").css("display", "none");
//     // alert(fired_button);
// });

$(document).on("click", "#header_order_history", function () {
  $("#order_details").css("display", "none");
  $("#order_details_top").css("display", "none");
  $("#order").css("display", "block");
  // alert(fired_button);
});

$(document).on("click", ".virw_order_detail_btnold", function (e) {
  var id = $(this).attr("id");
  $("#order_details").css("display", "block");
  $("#order_details_top").css("display", "block");
  $("#order").css("display", "none");
  $.ajax({
    //   url: BASEPATH + '/history/'+id,
    url: "../history/" + id,
    method: "get",
    datatype: "script",
    success: function (response) {
      var address = response["data"]["delivery_address"];
      var order = response["data"]["order_details"];
      var invoiceDetails = response["data"]["invoice_details"];
      var packageDetails = response["data"]["package_details"];
      var addText = address["receiver_name"] + "," + address["street_1"] + ",";
      if (address["street_2"] != "") addText = addText + address["street_2"];
      addText =
        address["city"] +
        "," +
        address["state"] +
        "," +
        address["country"] +
        "-" +
        address["postal_code"];
      $("#span_delivery_address").text(address["street_1"]);
      if (address["street_2"] != "") {
        $("#id_street2").css("display", "block");
        $("#span_delivery_address1").text(address["street_2"]);
      }
      $("#span_delivery_address2").text(addText);
      $("#span_order_number").text(order["order_number"]);
      $("#span_carrier").text(order["carrier_used"]);
      $("#span_shipping_cost").text("$" + order["shipping_cost"]);
      let current_datetime = new Date(order["processed_on"]);
      let d = current_datetime.getDate() + "";
      let m = current_datetime.getMonth() + 1 + "";
      let y = current_datetime.getFullYear();
      m = m.length == 2 ? m : "0" + m;
      d = d.length == 2 ? d : "0" + d;
      let formatted_date = d + "-" + m + "-" + y;
      $("#span_processed_on").text(formatted_date);
      $("#span_delivery_date").text(order["estimated_delivery_date"]);
      order["tracking_number"] == ""
        ? $("#span_tracking_num").text("N.A")
        : $("#span_tracking_num").text("#" + order["tracking_number"]);
      $("#lbl_order_shipped").text(order["order_status"]);
      $("#div_carrier_fees").text(
        "$" + invoiceDetails["carrier_fees"].toFixed(2)
      );
      $("#div_process_fees").text(
        "$" + invoiceDetails["process_fees"].toFixed(2)
      );
      $("#div_additional_fees").text(
        "$" + invoiceDetails["additional_fees"].toFixed(2)
      );
      $("#div_total_charges").text(
        "$" + invoiceDetails["total_charges"].toFixed(2)
      );
      $("#span_dimensions").text(
        packageDetails[0]["dimensions"] +
          " " +
          packageDetails[0]["dimensions_unit"]
      );
      $("#span_weight").text(
        packageDetails[0]["weight"] + " " + packageDetails[0]["weight_unit"]
      );
      // var date = Date.parse('2010-08-10');
      console.log(invoiceDetails["process_fees"].toFixed(2));
    },
    error: function (error) {
      console.log(error);
    },
  });
});

$(document).on("click", ".virw_order_detail_btn", function (e) {
  var id = $(this).attr("id");
  $("#div_loader").css("display", "block");
  $.ajax({
    url: "../history/savingHistoryId",
    // url: BASEPATH + '/history/savingHistoryId',
    method: "get",
    datatype: "html",
    data: { id: id },
    success: function (response) {
      $("#div_loader").css("display", "none");
      window.location.href = response;
    },
    error: function (error) {
      $("#div_loader").css("display", "none");
    },
  });
});

$(document).ready(function () {
  $("#div_loader").css("display", "none");
});
