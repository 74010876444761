

$(document).on("click", ".view_package_btn", function () {
  var id = $(this).data("id");
  $.ajax({
    url: "../mailboxes/ " + id,
    // url: BASEPATH + '/mailboxes/'+id,
    method: "get",
    datatype: "script",
    success: function (response) {
      $("#div_loader").css("display", "none");
    },
  });
});
