// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require('custom/bootstrap.min')
require("jquery")
// require("jquery-validation")
require('custom/popper.min')
require('custom/owl.carousel')
require('custom/owl.carousel.min')
// require('custom/wizard')
require('custom/select2.min.js')
require('custom/custom')
require('custom/jquery.creditCardValidator')

require('home')
require('history')
require('auto_shipment')
require('concierge')
require('mailboxes')
require('shipping_calculator')

// require ('custom/jquery_validation')
// require('@client-side-validations/client-side-validations')

global.toastr = require("toastr")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// $('#myWizard').wizard();
toastr.options.closeButton = true;
// toastr.options.timeOut = 0;
// toastr.options.extendedTimeOut = 0;
