// select address js

$(document).ready(function () {
  $("#div_loader").css("display", "none");
  $(document).on("click", "#btn_mailbox", function () {
    window.history.back();
  });

  $(document).on("click", "#btn_cancel", function () {
    window.history.back();
  });
});

// select payment methods

var valgt = document.querySelector("#valgt1");
$(document).on("click", 'input[type="radio"]', function (e) {
  $(this).closest(".crimeOption").trigger("click");
});

$(document).on("click", ".crimeOption", function () {
  $(".crimeOption").removeClass("checked");
  if ($(this).find('input[type="radio"]').is(":checked")) {
    $(this).find('input[type="radio"]').prop("checked", false);
    //$(this).addClass('checked');
  } else {
    $(this).find('input[type="radio"]').prop("checked", true);
    $(this).addClass("checked");
  }
});

// select services
