

// plan details js

$(document).on("click", "#customButton", function () {
  fbq("track", "Subscribe", {
    value: "14.99",
    currency: "USD",
    predicted_ltv: "30.0",
  });
});

$(document).on("click", "#customButton2", function () {
  fbq("track", "Subscribe", {
    value: "150",
    currency: "USD",
    predicted_ltv: "365.0",
  });
});

$(document).on("click", "#a_signup", function () {
  fbq("track", "CompleteRegistration");
});
