// $(".select2").select2({
//   selectOnClose: true
// });


$('#selectAll').click(function(e){
  var table= $(e.target).closest('table');
  $('td input:checkbox',table).prop('checked',this.checked);
});


$(".checkBoxID").click(function() {
  $("#buttonID").attr("disabled", !this.checked);
});

$(".add_new_card").click(function(){
  $(".payment_method").hide();
  $(".add_new_card_block").show();
  $(".saved_payment_method").hide();
});

$(".add_card_btn").click(function(){
  $(".saved_payment_method").show();
});


$(".add_card_btn").click(function(){
  $(".payment_method").show();
  $(".add_new_card_block").hide();
});

$(".add_new_address_btn").click(function(){
  $(".add_shipping_address").show();
  $(".shipping_address_block").hide();
});


$(".add_ship_address").click(function(){
  $(".add_shipping_address").hide();
  $(".shipping_address_block").show();
});


$(".view_package_btn").click(function(){
  $(".mailbox_item").hide();
  $(".view_package_block").show();
});


$(".split_package_btn").click(function(){
  $(".view_package_block").hide();
  $(".mailbox_item").show();
});

$(".virw_order_detail_btn").click(function(){
  $(".order_history").hide();
  $(".view_order_history_block").show();
});


$(".view_order_history").click(function(){
  $(".order_history").show();
  $(".view_order_history_block").hide();
});



// $(".select2").select2({
//   selectOnClose: true
// });





$(document).ready(function(){
  $('#date-time').hide();
  $('.shippackage_btn').hide();
  $('#switch1').change(function(){
    $('#date-time').toggle();
    $('.shippackage_btn').toggle();
  });
});



$('[name=tab]').each(function(i,d){
  var p = $(this).prop('checked');
//   console.log(p);
if(p){
  $('article').eq(i)
  .addClass('on');
}
});

$('[name=tab]').on('change', function(){
  var p = $(this).prop('checked');

  // $(type).index(this) == nth-of-type
  var i = $('[name=tab]').index(this);

  $('article').removeClass('on');
  $('article').eq(i).addClass('on');
});


/*$(document).ready(function(){
                                                  $(window).scroll(function () {

                                                   if($(window).scrollTop() > 0) {
                                                    $('.sidebar').css('position','fixed');
                                                    $('.sidebar').css('top','94px');
                                                  }

                                                  else if ($(window).scrollTop() <= 0) {
                                                    $('.sidebar').css('position','');
                                                    $('.sidebar').css('top','');
                                                  }
                                                  if ($('.sidebar').offset().top + $(".sidebar").height() > $(".main_footer").offset().top) {
                                                    $('.sidebar').css('top',-($(".sidebar").offset().top + $(".sidebar").height() - $(".main_footer").offset().top));
                                                  }
                                                });
                                                });
                                                */
/*
$(document).ready(function() {
  var $window = $(window);
  var $sidebar = $(".sidebar");
  var $sidebarHeight = $sidebar.innerHeight();
  var $footerOffsetTop = $(".main_footer").offset().top;
  var $sidebarOffset = $sidebar.offset();

  $window.scroll(function() {


    if($window.scrollTop() > $sidebarOffset.top) {
      $sidebar.addClass("fixed");
    } else {
      $sidebar.removeClass("fixed");
    }




    if($window.scrollTop() + $sidebarHeight > $footerOffsetTop) {
      $sidebar.css({"top" : -($window.scrollTop() + $sidebarHeight - $footerOffsetTop)});
    } else {
      $sidebar.css({"top": "94px",});
    }
  });


});*/




if ($(window).width() <= 991) {
  $(".page-content > .container").removeClass();



  $("div#exTab1").show();
  $(".navbar-brand").click(function(){
    $("div#exTab1").toggle();
    $('.right_side_part').toggleClass('open');

  });

}


// $(".country").select2({
//   placeholder: "Select country",
//   allowClear: false
// });
//
// $(".state").select2({
//   placeholder: "Select state",
//   allowClear: false
// });
//
//
// $(".city").select2({
//   placeholder: "Select city",
//   allowClear: false
// });
//
//
// $(".price").select2({
//   placeholder: "Price",
//   allowClear: false
// });

$(document).ready(function(){
    $(".with-color").click(function () {
       if($(this).hasClass("btn-warning"))
       {
          $(this).addClass("btn-danger");
          $(this).removeClass("btn-warning");
       }
       else{
          $(this).addClass("btn-warning");
          $(this).removeClass("btn-danger");
       }
    });

    $(".pushme2").click(function(){
    $(this).text(function(i, v){
       return v === 'Kg/Cm' ? 'Lbs/Inch' : 'Kg/Cm'
    });
    });

    view_package_detail()
});


$(document).ready(function() {
  $('.select2').select2({

  });

  // console.log()
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    }
});
$(".unit_system_menu").click(function(){
  $(".btn-secondary").toggleClass("active");
});
function view_package_detail(){
  // console.log("On Click button");
  
}
